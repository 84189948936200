.info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 19px;
  font-weight: bold;
  background: white;
  color: #1982F4;
  //text-align: center;
  //vertical-align: middle;
  border-radius: 12px;
  height: 45px;
  text-align: center;
}