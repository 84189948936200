.block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  &__title {

  }

  &__menu {
    display: flex;
    text-align: center;

    .profile {
      margin-right: 4px;
      font-size: 16px;
      font-weight: bold;
      color: #1982F4;
    }
  }
}
