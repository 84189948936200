.calendarContainer {
  padding: 16px;
  background-color: white;
  box-shadow: 0 5px 24px 16px rgba(0, 0, 0, 0.05);
  border-radius: 11px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.calendarDateMonth {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
}

.calendarText {
  color: #828282;
  font-size: 20px;
}

.calendarWorkspace {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4px;
}

.calendarMarkupDay {
  position: absolute;
  top: -8px;
  right: -8px;
  color: white;
  background-color: #FF6B00;
  height: 17px;
  display: flex;
  padding: 0 5px;
  border-radius: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.calendarMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__cursor {
    cursor: pointer;
    height: 24px;
  }
  &__text {
    color: #1982F4;
    font-size: 16px;
    font-weight: 400;
  }
}

.calendarDateCurrent {
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 7px;
  padding: 4px;
  font-size: 14px;
}

.task {
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 4px;

  &__checkbox {
    padding: 2px 0;
    margin-right: 4px;
  }
}

.dialogTitle {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 0;
}

.dialogActions {
  display: flex;
  width: 320px;
  padding: 0 16px 16px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}