.info_container {
  width: 100%;
  background-color: white;

  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.info_back {
  margin: 11px;
  color: #A4A4A4;
  font-size: 17px;
  border-bottom: 1px solid #E3E3E3;
}

.info_data {
  text-align: center;
  display: flex;
  flex-direction: column;

  &__nickname {
    color: #1982F4;
    font-size: 19px;
  }

  &__subtitle {
    color: #676D74;
    font-size: 12px;
  }
}

.buttons {
  margin-top: 13px;
  border-radius: 12px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 16px;
}