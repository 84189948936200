.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 11px;
  margin-top: 16px;
  background: linear-gradient(to right, #FF713C, #F5562A, #FAEADC);
  color: white;
  //text-align: center;
  //vertical-align: middle;
  border-radius: 4px;
  height: 45px;
}