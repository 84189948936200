.back_button {
  color: #A4A4A4;
  font-weight: 590;
  border-bottom: 2px solid #A4A4A4;
  background-color: white;
  display: flex;
  align-items: center;
}

.info_container {
  width: 100%;
  background-color: white;

  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.info_back {
  margin: 11px;
  color: #A4A4A4;
  font-size: 17px;
  border-bottom: 1px solid #E3E3E3;
}