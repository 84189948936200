.water-glasses {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: 769px) {
    max-width: 350px;
  }
}

.water-glass {
  position: relative;
  cursor: pointer;

  &__text {
    position: absolute;
    top: 17px;
    left: 4.3px;
    font-size: 6px;
    line-height: 5px;
    margin: 0;
    text-align: center;
    font-family: 'Source Sans Pro', sans-serif;
    color: #7FDAEF;
  }
}