.container {
  text-align: center;
  background-color: white;
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);  // pizdec kakoi govnocode
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
}

.footer {
  background-color: #FFFFFF;
  border-top: 1px solid #ECECEC;
  height: 100vw;
  position: relative;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%); // тут тоже
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 11px;
  margin-top: 16px;
  margin-left: 24px;
  margin-right: 24px;
  background: linear-gradient(to right, #FF713C, #F5562A, #FAEADC);
  color: white;
  //text-align: center;
  //vertical-align: middle;
  border-radius: 4px;
  height: 45px;

}


